import Vue from "vue";
import store from "./store";

import VueDPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";

import "view-design/dist/styles/iview.css";
import VueMarkdown from 'vue-markdown'
Vue.component('vue-markdown', VueMarkdown)
import {
    Button,
    Table,
    Input,
    Select,
    Option,
    Message,
    Badge,
    Divider,
    Icon,
    Page,
    Drawer,
    Modal,
    Spin,
    Menu,
    MenuItem,
    MenuGroup,
    Submenu,
    Col,
    Dropdown,
    DropdownItem,
    Upload,
} from "view-design";
Vue.component("Button", Button);
Vue.component("Table", Table);
Vue.component("Input", Input);
Vue.component("Select", Select);
Vue.component("Option", Option);
Vue.component("Divider", Divider);
Vue.component("Icon", Icon);
Vue.component("Page", Page);
Vue.component("Badge", Badge);
Vue.component("DPlayer", VueDPlayer);
Vue.component("Drawer", Drawer);
Vue.component("Modal", Modal);
Vue.component("Spin", Spin);
Vue.component("Menu", Menu);
Vue.component("MenuItem", MenuItem);
Vue.component("MenuGroup", MenuGroup);
Vue.component("Submenu", Submenu);
Vue.component("Col", Col);
Vue.component("Dropdown", Dropdown);
Vue.component("DropdownItem", DropdownItem);
Vue.component("Upload", Upload);
Vue.prototype.$Message = Message;
Vue.prototype.$Spin = Spin;
Vue.config.productionTip = false;

import router from "./router";
import App from "./App.vue";

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
